@font-face {
    font-family: 'Raleway';
    font-weight: 300;
    src: local('Raleway'), url('assets/fonts/Raleway-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 400;
    src: local('Raleway'), url('assets/fonts/Raleway-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 500;
    src: local('Raleway'), url('assets/fonts/Raleway-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 600;
    src: local('Raleway'), url('assets/fonts/Raleway-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 700;
    src: local('Raleway'), url('assets/fonts/Raleway-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 800;
    src: local('Raleway'), url('assets/fonts/Raleway-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 900;
    src: local('Raleway'), url('assets/fonts/Raleway-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: local('OpenSans'), url('assets/fonts/OpenSans-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: local('OpenSans'), url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: local('OpenSans'), url('assets/fonts/OpenSans-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: local('OpenSans'), url('assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: local('OpenSans'), url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    src: local('OpenSans'), url('assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  }

  :root {
    --primary: #ed1e79;
    --primary-light: #ffc5da;
    --primary-dark: #f61275;
    --primary-soft: #ff99c3;
    
    --blue: #215aea;
    --blue-light: #69A2E2;
    --blue-dark: #0A1E36;
    --blue-soft: #F1F6FC;
    --blue-transparent: #0a1e363b;


    --green: #599F57;
    --green-light: #97C596;
    --green-dark: #0C340B;
    --green-soft: #F1F7F1;

    --red: #E52C39;
    --red-light: #F08990;
    --red-dark: #340B0E;
    --red-soft: #FDECED;

    --orange: #F59A11;
    --orange-light: #F9C371;
    --orange-dark: #34240B;
    --orange-soft: #FEF6EA;
    
    --gray: #A0A0A0;
    --gray-light: #DCDCDC;
    --gray-dark: #383838;
    --gray-soft: #F5F5F5;

    
    --black: #080808;
    --white: #F8F8F8;


    --page-margin: 0 auto;
    --page-containter: min(1280px, 90%);
  }

  body {
    scrollbar-gutter: stable;
  }

  h1, h2, h3, h4, h5, h6, p, a, li, span, label, button, input, optgroup, select, textarea, .header, pre, .ls-text {
    font-family: 'Raleway', 'OpenSans', sans-serif !important;
    font-style: normal;
    font-size: 1.17em;
    transition: 0.4s;
    margin: 0;
}


/* input {
  font-size: 16px !important;
}

input:focus-visible {
  outline: none;
  box-shadow: 0px 4px 16px rgb(54 68 136 / 16%) !important;
}

input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }

input:disabled {
  opacity: 0.2;
} */

/* button {
  border: none;
} */

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a5a9b8;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a5a9b8;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #a5a9b8;
  }

  .dimmer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #000000a3;
    z-index: -1;
    transition: opacity 0.4s;
    opacity: 0;
  }

  .dimmer.active {
    z-index: 2;
    opacity: 1;
  }

  .ui.container {
    width: 90vw !important;
    margin-left: 5vw  !important;
    margin-right: 5vw  !important;
  }


  /*******************************
         SUI Overrides
*******************************/

.ui.button, .ui.active.button {
  padding: 0.5em 1em;
  border-radius: 2em;
  font-size: 1em;
}

.ui.button.transparent, .ui.button.transparent:hover, .ui.button.transparent:active {
  background-color: transparent;
  padding: 0;
}

.ui.primary.button {
  background-color: var(--primary);
  box-shadow: 0px 4px 12px var(--primary-light);
}

.ui.primary.button:hover {
  background-color: var(--primary-dark);
  box-shadow: 0px 4px 12px 3px var(--primary-light);
}

.ui.blue.button {
  box-shadow: 0px 4px 12px var(--blue);
}

.ui.primary.button:hover {
  background-color: var(--blue-dark);
  box-shadow: 0px 4px 12px 3px var(--blue-light);
}

.ui.input > input,
.ui.search.selection.dropdown > input.search {
  padding: 0.4em 0.8em;
  font-size: 1.17em;
  width: 240px;
}

.ui.selection.dropdown {
  padding: 0.4em 0.8em;
  min-height: 2.478em;
  width: 240px;
}

.ui.search.dropdown > .text{
  font-size: 1.17em;
  line-height: 1.3em;
}

a {
  color: var(--primary-light);
}





@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(190px);
  }
}

.appear-animation-left {
  animation-name: fromLeft;
  animation-duration: 1s;
}

.appear-animation-bottom {
  animation-name: fromBottom;
  animation-duration: 1s;
}

@keyframes fromLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}